import { MouseEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import EditOutlined from '@ant-design/icons/EditOutlined'
import ProfileOutlined from '@ant-design/icons/ProfileOutlined'
import LogoutOutlined from '@ant-design/icons/LogoutOutlined'
import UserOutlined from '@ant-design/icons/UserOutlined'
import WalletOutlined from '@ant-design/icons/WalletOutlined'
import { useAuthStore } from '@/stores/AuthStore.tsx'

export default function ProfileTab() {
    const [selectedIndex, setSelectedIndex] = useState(0)
    const navigate = useNavigate()

    const { logout } = useAuthStore()

    const handleListItemClick = (_event: MouseEvent<HTMLDivElement>, index: number, link: string) => {
        setSelectedIndex(index)
        navigate(link)
    }

    return (
        <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
            <ListItemButton
                selected={selectedIndex === 0}
                onClick={(event: MouseEvent<HTMLDivElement>) =>
                    handleListItemClick(event, 0, '/apps/profiles/user/personal')
                }
            >
                <ListItemIcon>
                    <EditOutlined />
                </ListItemIcon>
                <ListItemText primary="Edit Profile" />
            </ListItemButton>
            <ListItemButton
                selected={selectedIndex === 1}
                onClick={(event: MouseEvent<HTMLDivElement>) =>
                    handleListItemClick(event, 1, '/apps/profiles/account/basic')
                }
            >
                <ListItemIcon>
                    <UserOutlined />
                </ListItemIcon>
                <ListItemText primary="View Profile" />
            </ListItemButton>

            <ListItemButton
                selected={selectedIndex === 3}
                onClick={(event: MouseEvent<HTMLDivElement>) =>
                    handleListItemClick(event, 3, 'apps/profiles/account/personal')
                }
            >
                <ListItemIcon>
                    <ProfileOutlined />
                </ListItemIcon>
                <ListItemText primary="Social Profile" />
            </ListItemButton>
            <ListItemButton
                selected={selectedIndex === 4}
                onClick={(event: MouseEvent<HTMLDivElement>) =>
                    handleListItemClick(event, 4, '/apps/invoice/details/1')
                }
            >
                <ListItemIcon>
                    <WalletOutlined />
                </ListItemIcon>
                <ListItemText primary="Billing" />
            </ListItemButton>
            <ListItemButton
                selected={selectedIndex === 2}
                onClick={() => {
                    logout().then(() => {
                        navigate('/')
                    })
                }}
            >
                <ListItemIcon>
                    <LogoutOutlined />
                </ListItemIcon>
                <ListItemText primary="Logout" />
            </ListItemButton>
        </List>
    )
}
