import { OptionsObject, SharedProps, SnackbarMessage } from 'notistack'

export default class ErrorService {
    private static enqueueSnackbar: ((msg: SnackbarMessage, options?: OptionsObject) => void) | null = null

    public static setEnqueueSnackbar(fn: (msg: SnackbarMessage, options?: OptionsObject) => void) {
        this.enqueueSnackbar = fn
    }

    public static notifyError(message: string, options: SharedProps = {}) {
        ErrorService.notify(message, { ...options, variant: 'error' })
    }

    public static notifySuccess(message: string, options: SharedProps = {}) {
        ErrorService.notify(message, { ...options, variant: 'success' })
    }

    public static notifyInfo(message: string, options: SharedProps = {}) {
        ErrorService.notify(message, { ...options, variant: 'info' })
    }

    public static notifyWarning(message: string, options: SharedProps = {}) {
        ErrorService.notify(message, { ...options, variant: 'warning' })
    }

    private static notify(message: string, options: SharedProps = {}) {
        if (!this.enqueueSnackbar) {
            console.error(message)
            return
        }
        this.enqueueSnackbar(message, { ...options })
    }
}
