import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { AuthApi } from '@/api/backend.client.ts'
import { AuthToken, User } from '@/api/backend.types.ts'
import { isAfter } from 'date-fns'

interface AuthState {
    user: User | null
    getUserName: () => string
    setUser: (user: User | null) => void

    auth: AuthToken | null
    setAuth: (auth: AuthToken | null) => void

    logout: () => Promise<void>
    isAuthenticated: () => boolean
    getAuthToken: () => string | null
}

// type PersistedAuthState = Pick<AuthState, 'user' | 'auth'>

export const useAuthStore = create<AuthState>()(
    persist(
        (set, get) => ({
            user: null,
            setUser: (user) => set({ user }),
            auth: null,
            setAuth: (auth) => set({ auth }),
            logout: async () => {
                try {
                    sessionStorage.clear()
                    localStorage.clear()
                    await AuthApi.logout()
                } catch (err: unknown) {
                    console.error('Logout error:', err)
                } finally {
                    set({ user: null, auth: null })
                }
            },
            getUserName: () => {
                if (!get().isAuthenticated()) {
                    return 'Guest'
                }
                const user = get().user
                return `${user?.first_name} ${user?.last_name}`.trim()
            },
            getAuthToken: () => get().auth?.token ?? null,
            isAuthenticated: () => {
                const user = get().user
                const auth = get().auth

                // Check if user and auth token are present
                if (user === null || auth === null) return false

                // Check if the auth token is expired
                if (isAfter(new Date(), auth.expires_at)) {
                    return false
                }

                // All is good
                return true
            },
        }),
        {
            name: 'auth',
            partialize: (state) => ({ auth: state.auth, user: state.user }),
        }
    )
)
