import { useAuthStore } from '@/stores/AuthStore.tsx'
import { PerfbaseException } from '@/exceptions/PerfbaseException'

export class ApiClient {
    static get<T>(endpoint: string, headers?: Record<string, string>) {
        return this.request<T>(endpoint, 'GET', undefined, headers)
    }

    static post<T>(endpoint: string, body?: unknown, headers?: Record<string, string>) {
        return this.request<T>(endpoint, 'POST', body, headers)
    }

    static put<T>(endpoint: string, body: unknown, headers?: Record<string, string>) {
        return this.request<T>(endpoint, 'PUT', body, headers)
    }

    static patch<T>(endpoint: string, body: unknown, headers?: Record<string, string>) {
        return this.request<T>(endpoint, 'PATCH', body, headers)
    }

    static delete<T>(endpoint: string, headers?: Record<string, string>) {
        return this.request<T>(endpoint, 'DELETE', undefined, headers)
    }

    private static async request<T>(
        endpoint: string,
        method = 'GET',
        body?: unknown,
        headers: Record<string, string> = {}
    ): Promise<T> {
        // Add the Authorization header if the user is authenticated
        const authState = useAuthStore.getState()
        if (authState.isAuthenticated()) {
            headers.Authorization = `Bearer ${authState.getAuthToken()}`
        }

        // Make the request
        const response = await fetch(`${import.meta.env.VITE_API_BASE_URL}${endpoint}`, {
            method,
            headers: {
                'Content-Type': 'application/json',
                ...headers,
            },
            body: body ? JSON.stringify(body) : undefined,
        })

        // Parse the JSON response
        const json = await response.json()

        // Handle errors
        this.handleErrors(response, json)

        // Return the JSON response
        return json
    }

    private static handleErrors(response: Response, json: unknown): PerfbaseException | null {
        if (!response.ok) {
            const errorJson = json as { data: { success: boolean; exception: string; message: string } } // Explicit assertion
            console.log(errorJson)
            if (
                errorJson?.data &&
                errorJson?.data.success === false &&
                errorJson?.data.exception &&
                errorJson?.data.message
            ) {
                throw new PerfbaseException(response.status, errorJson.data.exception, errorJson.data.message)
            } else {
                throw new Error(`Unexpected API error: ${response.statusText}`)
            }
        }
        return null
    }
}
