import React, { useMemo } from 'react'
import { AvatarProps as MuiAvatarProps, default as MuiAvatar } from '@mui/material/Avatar'
import { SxProps } from '@mui/system'
import { Theme } from '@mui/material/styles'

type AvatarSize = 'xs' | 'small' | 'medium' | 'large'

interface AvatarProps extends MuiAvatarProps {
    size?: AvatarSize
    sx?: SxProps<Theme>
    initialCount?: number
    children: string
}

const sizeMap = {
    xs: 20,
    small: 24,
    medium: 32,
    large: 40,
}

function extractSyllableInitials(word: string, maxInitials: number | null) {
    const vowels = 'aeiou'
    return word
        .split('')
        .filter((char, index) => index === 0 || vowels.includes(char.toLowerCase()))
        .slice(0, maxInitials ?? undefined) // handle null as "no limit"
        .map((char) => char.toUpperCase())
        .join('')
}

function getInitials(input = '', maxInitials: number | null = null, extended = false) {
    const trimmed = input.trim()
    if (!trimmed) return '??'

    if (extended) {
        // Advanced functionality
        const capitalLetters = trimmed.match(/[A-Z]/g)
        if (capitalLetters && capitalLetters.length > 1) {
            return maxInitials === null ? capitalLetters.join('') : capitalLetters.slice(0, maxInitials).join('')
        }

        const words = trimmed
            .split(' ')
            .map((word) => word.replace(/[^a-zA-Z]/g, '')) // remove non-alpha
            .filter((word) => word.length > 0)

        if (words.length > 1) {
            // multi-word logic
            const extracted = words.map((word) => word.charAt(0).toUpperCase())
            return maxInitials === null ? extracted.join('') : extracted.slice(0, maxInitials).join('')
        }

        // fallback to syllable-based
        const syllableBased = extractSyllableInitials(words[0] ?? '', maxInitials)
        if (syllableBased.length > 0) {
            return syllableBased
        }
    }

    // default: first letter of each word
    const words = trimmed.split(' ').filter(Boolean)
    const extracted = words.map((word) => word.charAt(0).toUpperCase())
    const result = maxInitials === null ? extracted.join('') : extracted.slice(0, maxInitials).join('')

    return result || '??'
}

function stringToColor(string: string) {
    let hash = 0

    for (let i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash)
    }

    let color = '#'
    for (let i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff
        color += `00${value.toString(16)}`.slice(-2)
    }

    return color
}

const Avatar: React.FC<AvatarProps> = ({ size = 'medium', initialCount = 2, sx, children, ...props }) => {
    const name = React.Children.toArray(children).join(' ').trim().replace(/\s+/g, ' ')
    const dimension = sizeMap[size]

    // Memoize the calculations so they only run when `name` changes
    const memoizedColor = useMemo(() => stringToColor(name), [name])
    const memoizedInitials = useMemo(() => getInitials(name, initialCount), [name, initialCount])

    return (
        <MuiAvatar
            variant="rounded"
            sx={{
                width: dimension,
                height: dimension,
                bgcolor: memoizedColor,
                ...sx,
            }}
            {...props}
        >
            {memoizedInitials}
        </MuiAvatar>
    )
}

export default Avatar
