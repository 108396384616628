import * as v from 'valibot'
import typia from 'typia'

// Trace
export interface Trace {
    trace_uuid: string
    action: string
    user_id: string
    user_ip: string
    user_agent: string
    hostname: string
    environment: string
    app_version: string
    php_version: string
    http_method: string
    http_status_code: number
    http_url: string
    call_count: number
    wall_time: number
    user_cpu_time: number
    system_cpu_time: number
    memory_allocated_peak: number
    memory_allocated: number
    memory_freed: number
    symbol_count: number
    duration: number
    created_at: string
}

// Action
export interface Action {
    action: string
    action_uuid: string
    count: number
    min_duration: number
    max_duration: number
    median_duration: number
    avg_duration: number
    last_seen_at: string
    oldest_uuid: string
    newest_uuid: string
    http_methods: string[]
    http_status_codes: number[]
    app_versions: string[]
    php_versions: string[]
}

// CRUD Permissions
export interface CrudPermissions {
    create: boolean
    read: boolean
    update: boolean
    delete: boolean
}

// Subscription
export interface Subscription {
    id: number
    name: string
    ttl_days: number
}

// Project
export interface Project {
    id: string
    name: string
    region: string
    created_at: string
    updated_at: string
    permissions?: CrudPermissions
    subscription?: Subscription
    organisation?: Organisation
}

export const ProjectCreateValidator = v.object({
    name: v.string(),
    region: v.string(),
    subscription_id: v.pipe(v.string(), v.uuid()),
    organisation_id: v.pipe(v.string(), v.uuid()),
})

export const ProjectUpdateValidator = v.object({
    name: v.optional(v.string()),
})

// Organisation
export interface Organisation {
    id: string
    name: string
    slug: string
    created_at: string
    updated_at: string
    user_role?: string // dynamic
    permissions?: CrudPermissions
    user_count?: number // dynamic
    project_count?: number // dynamic
}

export interface OrganisationStats {
    organisation_uuid: string
    project_uuid: string
    avg_duration: number
    p50_duration: number
    p95_duration: number
    p99_duration: number
    p100_duration: number
    max_duration: number
    min_duration: number
    duration_variance: number
    client_error_count: number
    server_error_count: number
    unique_users: number
    unique_user_ips: number
    unique_user_agents: number
    action_count: number
    unique_http_urls: number
    unique_http_methods: number
    max_call_count: number
    median_call_count: number
    p95_call_count: number
    total_user_cpu_time: number
    total_system_cpu_time: number
    total_cpu_time: number
    p95_user_cpu_time: number
    p99_user_cpu_time: number
    p95_system_cpu_time: number
    p99_system_cpu_time: number
    total_memory_allocated: number
    total_memory_freed: number
    peak_memory: number
    avg_memory: number
    p50_memory: number
    p95_memory: number
    p99_memory: number
    p100_memory: number
    p95_symbol_count: number
    request_count: number
    get_requests: number
    post_requests: number
    put_requests: number
    patch_requests: number
    delete_requests: number
    unique_status_codes: number[]
    unique_status_codes_count: number
    unique_environments: string[]
    unique_environments_count: number
    unique_app_versions: string[]
    unique_app_versions_count: number
    unique_php_versions: string[]
    unique_php_versions_count: number
    created_at: Date
}

// User
export interface User {
    id: string
    first_name: string
    last_name: string
    email: string
    avatar_url: string
    created_at: string
    updated_at: string
    organisations?: Organisation[]
}

export interface UserInvite {
    id: number
    email: string
    role: string
    expires_at: Date
    created_at: string
    updated_at: string
    invited_by: User
}

export const UserInviteValidator = v.object({
    email: v.pipe(v.string(), v.email()),
    role: v.string(),
})

export const OrganisationUpdateValidator = v.object({
    name: v.string(),
})

export const OrganisationCreateValidator = v.object({
    name: v.string(),
})

export const UserRegisterValidator = v.object({
    first_name: v.string(),
    last_name: v.string(),
    organisation_name: v.string(),
    email: v.string(),
    marketing_opt_in: v.boolean(),
    agree_terms: v.boolean(),
})

export const UserEmailVerifyValidator = v.object({
    email: v.string(),
    token: v.string(),
    password: v.string(),
})

export const UserLoginValidator = v.object({
    email: v.string(),
    password: v.string(),
})

export const UserUpdateValidator = v.object({
    first_name: v.optional(v.string()),
    last_name: v.optional(v.string()),
    email: v.optional(v.string()),
    password: v.optional(v.string()),
})

// Data Region
export const DataRegionValidator = v.object({
    name: v.string(),
    description: v.string(),
    flag: v.pipe(v.string(), v.emoji()),
})
export type DataRegion = v.InferInput<typeof DataRegionValidator>

// ======================================== //

interface PerfbaseApiResponse {
    data?: unknown
    meta?: unknown
}

export const isBooleanResponse = typia.createIs<BooleanResponse>()
export type BooleanResponse = PerfbaseApiResponse & {
    data: {
        success: boolean
        message?: string
    }
}

export type HealthCheckResponse = PerfbaseApiResponse & {
    data: {
        service: string
        status: string
        environment: string
        version: string
        timestamp: string
        checks: Record<string, string>
    }
}

export type DataRegionResponse = PerfbaseApiResponse & {
    data: {
        regions: Record<string, DataRegion>
    }
}

export interface AuthToken {
    token: string
    expires_at: string
    valid_from: string
}

export const isUserAuthTokenResponse = typia.createIs<UserAuthTokenResponse>()
export const validateUserAuthTokenResponse = typia.createValidate<UserAuthTokenResponse>()
export type UserAuthTokenResponse = PerfbaseApiResponse & {
    data: {
        user: User
        auth: AuthToken
    }
}

export type AuthTokenResponse = PerfbaseApiResponse & {
    data: AuthToken
}

export type UserResponse = PerfbaseApiResponse & {
    data: User
}

interface Paginator {
    total: number
    count: number
    per_page: number
    current_page: number
    total_pages: number
    links: {
        next: string
        previous: string
    }
}

export type OrganisationListResponse = PerfbaseApiResponse & {
    data: Organisation[]
    meta: {
        pagination: Paginator
    }
}

export type OrganisationResponse = PerfbaseApiResponse & {
    data: Organisation
}

export type OrganisationStatsResponse = PerfbaseApiResponse & {
    data: OrganisationStats
}

export type UserListResponse = PerfbaseApiResponse & {
    data: User[]
    meta: {
        pagination: Paginator
    }
}

export type ProjectListResponse = PerfbaseApiResponse & {
    data: Project[]
    meta: {
        pagination: Paginator
    }
}

export type ProjectResponse = PerfbaseApiResponse & {
    data: Project
}

export interface ApiKeyResponse {
    success: boolean
    api_key: string
}

export type ActionListResponse = PerfbaseApiResponse & {
    data: Action[]
    meta: {
        pagination: Paginator
    }
}

export type ActionResponse = PerfbaseApiResponse & {
    data: Action
}

export type TraceListResponse = PerfbaseApiResponse & {
    data: Trace[]
    meta: {
        pagination: Paginator
    }
}

export type TraceResponse = PerfbaseApiResponse & {
    data: Trace
}

export type UserInviteListResponse = PerfbaseApiResponse & {
    data: UserInvite[]
    meta: {
        pagination: Paginator
    }
}
