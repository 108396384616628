export class PerfbaseException extends Error {
    statusCode: number
    message: string

    constructor(statusCode: number, exception: string, message: string) {
        super(message)
        this.name = exception
        this.statusCode = statusCode
        this.message = message

        // Set the prototype explicitly for custom Error class in TypeScript
        Object.setPrototypeOf(this, PerfbaseException.prototype)
    }
}
